import { isLocalhost, spesillAIBaseURL } from "@spesill/config/env";

import { getServerIdToken } from "./apiClient";

// このインターフェースはエンドポイントによって異なるので、エンドポイントごとにインターフェースを定義する
interface ChatApiPayload {
  user_id: string;
  chat_room_id: string;
  tenant_id: string;
  group_id: string;
  question: string;
}

type SSEEventCallback = (event: MessageEvent) => void;

class SSEClient {
  private baseURL: string;

  constructor(baseURL?: string) {
    this.baseURL = baseURL || spesillAIBaseURL();
  }

  private async getAuthHeaders(endpoint: string): Promise<Headers> {
    const token = isLocalhost()
      ? undefined
      : await getServerIdToken(`${this.baseURL}/${endpoint}`);
    return new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
  }

  async sendMessage(
    endpoint: string,
    payload: ChatApiPayload,
  ): Promise<Response> {
    const headers = await this.getAuthHeaders(endpoint);
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
  }

  async streamResponse(
    endpoint: string,
    payload: ChatApiPayload,
    onMessage: SSEEventCallback,
    onError?: (error: Error | unknown) => void,
  ): Promise<void> {
    try {
      const response = await this.sendMessage(endpoint, payload);
      if (!response.body) {
        throw new Error("レスポンスボディが読み取れません");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      while (!done) {
        const { done: doneFlag, value } = await reader.read();
        done = doneFlag;
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        onMessage(new MessageEvent("message", { data: chunk }));
      }
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        console.error("SSE streaming error:", error);
      }
    }
  }
}

export const sseClient = new SSEClient();
